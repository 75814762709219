import { gql } from 'graphql-request';

export const app = gql`
query($id: ID!) {
  app(id: $id) {
    id
    imgPath
    link
    qrCodeImgPath
    filename
    version {
      id
      name
      version
      size
      publishedOn
      linkDownload
    }
  }  
}
`