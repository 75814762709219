import { useParams } from 'react-router-dom'
import { useAppsQuery } from '../graphql/hooks/app'
import warningIcon from '../assets/warning.png'
import downloadIcon from '../assets/download_icon.png'
import { parse, format } from 'date-fns'
// import arrowIcon from '../assets/arrow.png'
// import { useNavigate } from 'react-router-dom'
import { useCategoriesQuery } from '../graphql/hooks/categories'
import { Category } from '../types/categories'
import { App } from '../types/app'
import React, { useState, useEffect} from 'react'
import Spinner from '../assets/loader.gif'

export const AppItemDetails: React.FC = () => {

  const { data: categories, isLoading: categoriesLoading } = useCategoriesQuery()
  const [foundApp, setFoundApp] = useState<App | undefined>(undefined)
  const { link } = useParams<{ link: string }>()

  useEffect(() => {
    if (categories) {
      const allApps = categories.flatMap((category: Category) => category.apps)
      const app = allApps.find((app: App) => app.link === link)
      setFoundApp(app)
    }
  }, [categories, link])

  const { data: app, isLoading: appLoading } = useAppsQuery({ id: foundApp?.id || '' })

  const SpinnerComponent: React.FC = () => (
    <div className="spinner-container">
      <div className="spinner-content">
        <img src={Spinner} alt="Loading" />
        <div className="spinner-text">
          {'Daten werden geladen.'}
        </div>
      </div>
    </div>
  )

  if (categoriesLoading || appLoading) {
    return (
        <SpinnerComponent />
    )
  }


  let formattedDate = 'Invalid date'
  if (app?.version[0].publishedOn) {
    try {
      const parsedDate = parse(app?.version[0].publishedOn, 'yyyy-MM-dd', new Date())
      formattedDate = format(parsedDate, 'dd.MM.yyyy')
    } catch (error) {
      console.error('Error parsing date:', error)
    }
  }

  return (
    <div className='app-details-container'>
      <div className="app-details-position">
        <div className="app-details">
          <div className="app-details-title">
            <div className='app-details-box'>
              <img 
                src={app?.imgPath} 
                alt="Trainings Apps" 
                className='app-details-box-icon'
              />
            </div>
            <span className='app-details-info-name'>{app?.version[0].name}</span>
          </div>
          <div className="app-details-items">
            <div className="app-details-info">
              <span className='app-details-subtitle'>{`Version ${app?.version[0].version}`}</span>
              <div className='app-details-info-box app-info'>
                <div className='app-details-info-content'>
                  <div className='app-details-info-item'>
                    <span className='app-details-info-bold'>File-Name</span>
                    <span>{app?.filename}</span>
                  </div>
                  <div className='app-details-info-item'>
                    <span className='app-details-info-bold'>Größe</span>
                    <span>{`${app?.version[0].size} MB`}</span>
                  </div>
                  <div className='app-details-info-item'>
                    <span className='app-details-info-bold'>Veröffentlicht am</span>
                    <span>{formattedDate}</span>
                  </div>
                </div>
                <img className='app-details-info-qr' src={app?.qrCodeImgPath} alt='QR code'/>
              </div>
              <div className='app-details-info-qr-mobile'>
                <img src={app?.qrCodeImgPath} alt='QR code'/>
              </div>
              <a 
                className='app-details-button'
                href={app?.version[0].linkDownload}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img 
                  src={downloadIcon} 
                  alt='Download icon'
                  className='app-details-download'
                />
                Download
              </a>
            </div>
            <div className="app-details-warning">
              <div className='app-details-warning-title'>
                <img 
                  src={warningIcon} 
                  alt="Warning Icon" 
                  className='app-details-warning-icon'
                />
                <span className='app-details-subtitle'>Hinweis</span>
              </div>
              <div className='app-details-info-box app-warning'>
                <span>Bitte beachten Sie, dass vor dem Download der App alte Versionen von Ihrem Gerät deinstalliert werden. Andernfalls kann es zu Fehlern kommen.</span>
                <span>Bei technischen Problemen wenden Sie sich bitte an Ihre zuständige RVL.</span>
              </div>
            </div>
          </div>
          <div className="app-details-warn">
            <div className='app-details-warning-title'>
              <img 
                src={warningIcon} 
                alt="Warning Icon" 
                className='app-details-warning-icon'
              />
              <span className='app-details-subtitle'>Hinweis</span>
            </div>
            <div className='app-details-info-box app-warning'>
              <span>Bitte beachten Sie, dass vor dem Download der App alte Versionen von Ihrem Gerät deinstalliert werden. Andernfalls kann es zu Fehlern kommen.</span>
              <span>Bei technischen Problemen wenden Sie sich bitte an Ihre zuständige RVL.</span>
            </div>
          </div>
        </div>
        {/* <div 
          className="app-details-back"
          onClick={handleNavigate}
        >
          <img 
            src={arrowIcon}
            className='app-details-back-icon'
            alt='Back icon'
          />
          <span>zurück zur Übersicht</span>
        </div> */}
      </div>
    </div>
  )
}