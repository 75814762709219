import { useAppsQuery } from '../graphql/hooks/app'
import googleIcon from '../assets/googleMarket.png'
import appleIcon from '../assets/iTunes.svg'
import { parse, format } from 'date-fns'
// import arrowIcon from '../assets/arrow.png'
// import { useNavigate } from 'react-router-dom'

export const ApoCampusApp: React.FC = () => {
  const { data: app } = useAppsQuery({ id: '7'})
  // const navigate = useNavigate()
  // const handleNavigate = () => {
  //   navigate('/')
  // }

  const formatPublishedDate = (dateString: string) => {
    let formattedDate = 'Invalid date';
    if (dateString) {
      try {
        const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
        formattedDate = format(parsedDate, 'dd.MM.yyyy');
      } catch (error) {
        console.error('Error parsing date:', error);
      }
    }
    return formattedDate;
  };

  const iosPublishedDate = app?.version[1]?.publishedOn
    ? formatPublishedDate(app.version[1].publishedOn)
    : 'N/A';

  const androidPublishedDate = app?.version[0]?.publishedOn
    ? formatPublishedDate(app.version[0].publishedOn)
    : 'N/A';


  return (
    <div className='app-details-container'>
      <div className="app-details-position">
      <div className="app-details">
        <div className="app-details-title">
          <div className='app-details-box'>
            <img 
              src={app?.imgPath} 
              alt="Trainings Apps" 
              className='app-details-box-icon'
            />
          </div>
          <span>{app?.version[0].name}</span>
        </div>
        <div className="app-details-items">
          <div className="app-details-info apocampus">
            <div className='app-details-ios'>
              <span className='app-details-subtitle'>IOS</span>
              <div className='app-details-info-box'>
                <div className='app-details-info-content'>
                  <div className='app-details-info-item'>
                    <span className='app-details-info-bold'>Version</span>
                    <span>{app?.version[1]?.version}</span>
                  </div>
                  <div className='app-details-info-item'>
                    <span className='app-details-info-bold'>Größe</span>
                    <span>{`${app?.version[1]?.size} MB`}</span>
                  </div>
                  <div className='app-details-info-item'>
                    <span className='app-details-info-bold'>Veröffentlicht am</span>
                    <span>{iosPublishedDate}</span>
                  </div>
                </div>
              </div>
              <a 
                className='app-details-store'
                href={app?.version[1]?.linkDownload}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img 
                  src={appleIcon}
                  className='app-details-store-icon'
                  alt="Apple store"
                />
              </a>
            </div>
            <div className='app-details-android'>
              <span className='app-details-subtitle'>Android</span>
              <div className='app-details-info-box'>
                <div className='app-details-info-content'>
                  <div className='app-details-info-item'>
                    <span className='app-details-info-bold'>Version</span>
                    <span>{app?.version[0]?.version}</span>
                  </div>
                  <div className='app-details-info-item'>
                    <span className='app-details-info-bold'>Größe</span>
                    <span>{`${app?.version[0]?.size} MB`}</span>
                  </div>
                  <div className='app-details-info-item'>
                    <span className='app-details-info-bold'>Veröffentlicht am</span>
                    <span>{androidPublishedDate}</span>
                  </div>
                </div>
              </div>
              <a 
                className='app-details-store'
                href={app?.version[0]?.linkDownload}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img 
                  src={googleIcon}
                  className='app-details-store-icon'
                  alt="Google market"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="app-details-items apocampus-mobile">
        <div className="app-details-info apocampus">
            <div className='app-details-android-mobile'>
              <span className='app-details-subtitle'>Android</span>
              <div className='app-details-info-box'>
                <div className='app-details-info-content'>
                  <div className='app-details-info-item'>
                    <span className='app-details-info-bold'>Version</span>
                    <span>{app?.version[0]?.version}</span>
                  </div>
                  <div className='app-details-info-item'>
                    <span className='app-details-info-bold'>Größe</span>
                    <span>{`${app?.version[0]?.size} MB`}</span>
                  </div>
                  <div className='app-details-info-item'>
                    <span className='app-details-info-bold'>Veröffentlicht am</span>
                    <span>{androidPublishedDate}</span>
                  </div>
                </div>
              </div>
              <a 
                className='app-details-store'
                href={app?.version[0]?.linkDownload}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img 
                  src={googleIcon}
                  className='app-details-store-icon'
                  alt="Google market"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div 
        className="app-details-back"
        onClick={handleNavigate}
      >
        <img 
          src={arrowIcon}
          className='app-details-back-icon'
          alt='Back icon'
        />
        <span>zurück zur Übersicht</span>
      </div> */}
      </div>
    </div>
  )
}